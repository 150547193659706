import PropTypes from "prop-types";
import React from "react";
import FacebookIcon from '@material-ui/icons/Facebook';

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col h-screen">
      <Header />

      <main className="flex-1 overflow-y-auto bg-green-100 bg-opacity-25">
        {children}
      </main>

      <footer>
        <nav className="text-center text-white h-9 p-2">
          © {new Date().getFullYear()} | Rua da Vinha, nº 218 4560-573 Penafiel | 255 724 195 | 936 592 279 | geral@jisvicente.com | <a className="socialLink" href="https://www.facebook.com/jisvicente"><FacebookIcon fontSize="small" /></a>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
