import { Link } from "gatsby";
import React from "react";
import logo from "../images/jisvicente_logo.png";

function Header() {

  return (
    <header className="topBorder">
      <div className="flex w-full">
        <Link to="/" className="w-1/4">
          <h1 className="flex justify-end no-underline">
            <img alt="Jisvicente Logo"
                 className="w-32"
                 src={logo} />
          </h1>
        </Link>

        <nav
          className={`w-3/4 flex justify-center`}
        >
          {[
            {
              route: `/`,
              title: `Entrada`,
            },
            {
              route: `/about`,
              title: `Quem Somos`,
            },
            {
              route: `/galery`,
              title: `Galeria`,
            },
            {
              route: `/resources`,
              title: `Recursos Físicos`,
            },
            {
              route: `/registration`,
              title: `Pré-Inscrição`,
            },
            {
              route: `/contact`,
              title: `Contactos`,
            },
          ].map((link) => (
            <Link className={`text-xs md:text-sm menu w-24 h-full no-underline md:inline-block text-center`}
                  key={link.title}
                  to={link.route}>
              <div className="flex items-center justify-center h-full">{link.title}</div>
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
